import axios, { CancelTokenSource } from 'axios'

import { catchHttpError, getData } from './../helpers/api'
import { IPriceCheckBody, IPriceCheckResponse } from './price.types'

class PriceAPi {
  private baseUrl: string
  private cancelToken?: CancelTokenSource

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  public checkPrice(body: IPriceCheckBody): Promise<IPriceCheckResponse> {
    return new Promise((resolve, reject) => {
      this.cancelToken = axios.CancelToken.source()

      return axios
        .post(this.getUrl(), body, {
          cancelToken: this.cancelToken.token
        })
        .then(getData)
        .then((data: IPriceCheckResponse) => {
          if (!data.value && data.message) {
            reject(new Error(data.message))
          } else if (
            data.errors &&
            data.errors.length &&
            data.errors[0] &&
            data.errors[0].message
          ) {
            const { message } = data.errors[0]
            const path = data.errors[0].path || ''

            reject(new Error(`${path} ${message}`))
          } else {
            resolve(data)
          }
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public cancelCalling() {
    if (this.cancelToken) {
      this.cancelToken.cancel()
      this.cancelToken = undefined
    }
  }

  private getUrl(): string {
    return `${this.baseUrl}price`
  }
}

export default PriceAPi

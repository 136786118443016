/* eslint-disable prettier/prettier */
import { IHappeningDataReducer, IHappeningSelected } from './state.types'
const SECONDS_IN_MINUTE = 60

const getActivityDuration = (
  happening: IHappeningDataReducer,
  values: IHappeningSelected
) => {
  if (happening) {
    if (happening.spaces.length > 0) {
      const { timeSlot, timeBreak } = happening.spaces[0]
      let moreSlots = 0

      const numOfPlayers = values.selectionsPerPriceType.map(
        (selection) => selection.numberOfPlayers || 0
      ).reduce((a, b) => a + b, 0)

      if (happening.overbookedSlots && numOfPlayers) {
        moreSlots =
          Math.ceil(
            numOfPlayers / happening.spaces[0].maxNumberOfPeople
          ) - 1
      }
      const duration = Math.floor(
        (timeSlot * (happening.activityTimeInSlots + moreSlots)) /
          SECONDS_IN_MINUTE
      )

      if (timeBreak) {
        const time = duration - timeBreak / SECONDS_IN_MINUTE
        if (time > 0) {
          return time
        }
        return 0
      }

      return duration
    }
  }

  return null
}

export default getActivityDuration

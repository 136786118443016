import axios, { CancelTokenSource } from 'axios'
import format from 'date-fns/format'
import moment from 'moment'

import { catchHttpError, getData } from './../helpers/api'
import { IAvailabilitiesResponse } from './api.types'
import {
  IAvailabilitiesData,
  IAvailabilitiesSpace
} from './availabilities.types'

class AvailabilitiesApi {
  private baseUrl: string
  private cancelTokenAvailabilities?: CancelTokenSource

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  public getAvailabilities(
    happeningSlug: string,
    _date: Date
  ): Promise<IAvailabilitiesData> {
    return new Promise<IAvailabilitiesData>((resolve, reject) => {
      this.cancelTokenAvailabilities = axios.CancelToken.source()
      const date = format(_date, 'yyyy-MM-dd')

      return axios
        .get(this.getUrl(happeningSlug, date), {
          cancelToken: this.cancelTokenAvailabilities.token
        })
        .then(getData)
        .then((data: IAvailabilitiesResponse) => {
          resolve(this.normalizeAvailabilities(data))
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public cancelAvailabilities() {
    if (this.cancelTokenAvailabilities) {
      this.cancelTokenAvailabilities.cancel()
      this.cancelTokenAvailabilities = undefined
    }
  }

  private getUrl(happeningSlug: string, date: string): string {
    return `${this.baseUrl}happenings/${happeningSlug}/availability?date=${date}`
  }

  private normalizeAvailabilities(
    response: IAvailabilitiesResponse
  ): IAvailabilitiesData {
    if (!response) {
      return []
    }

    const result: IAvailabilitiesData = []

    for (const data of Object.keys(response.items)) {
      const slots = response.items[data]
      for (const slotName of Object.keys(slots)) {
        const slot = slots[slotName]

        let canBook = false
        let capacityLeft = 0
        const spaces: IAvailabilitiesSpace[] = []

        for (const space of Object.keys(slot)) {
          const spaceData: IAvailabilitiesSpace = slot[parseInt(space, 10)]
          canBook = canBook || spaceData.available
          if (capacityLeft < spaceData.capacityLeft) {
            capacityLeft = spaceData.capacityLeft
          }
          spaces.push(spaceData)
        }

        const duration = slot[0].duration

        const slotDateTime = moment(`${data} ${slotName}`).utc().valueOf()

        const reserved = spaces.reduce(
          (isReserved, space) => isReserved || space.reserved,
          false
        )

        result.push({
          capacityLeft,
          data,
          duration,
          isDisabled: !canBook,
          reserved,
          slotDateTime,
          spaces,
          start: slotName.substr(0, 5)
        })
      }
    }

    return result
  }
}

export default AvailabilitiesApi

/* eslint-disable prettier/prettier */
import moment from 'moment'
import {
  IAvailabilitiesData,
  IHappeningReducer,
  IHappeningSelected
} from './state.types'

const getAvailabilities = (
  happening: IHappeningReducer,
  selected: IHappeningSelected
): IAvailabilitiesData => {
  const numOfPlayers = selected.selectionsPerPriceType
    .map((selection) => selection.numberOfPlayers || 0)
    .reduce((a, b) => a + b, 0)

  const now = moment.utc().valueOf()

  if (happening.data?.overbookedSlots) {
    const maxNumberOfPeople = happening.data.spaces[0].maxNumberOfPeople
    const howManySlots = Math.ceil(numOfPlayers / maxNumberOfPeople)

    return happening.availabilities.map((slot, index) => {
      if (slot.isDisabled) {
        return slot
      }

      let isDisabled = false
      for (let i = 0; i < howManySlots; i++) {
        const j = index + i
        const slotExist = happening.availabilities.length > j
        if (!slotExist) {
          isDisabled = true
          break
        }
        const isAnySlotReserved = happening.availabilities[j].spaces.reduce(
          (reserved, space) => reserved || space.reserved,
          false
        )

        isDisabled = slot.isDisabled || isAnySlotReserved

        if (isDisabled) {
          break
        }
      }

      return { ...slot, isDisabled }
    })
  }

  if (happening.data?.calculatePricePerPerson) {
    const availableSlots: IAvailabilitiesData = []
    happening.availabilities.map((slot) => {
      const disableByTime =
        now > slot.slotDateTime &&
        happening.data?.partnerId !== 2520 &&
        happening.data?.partnerId !== 2585

      if (slot.capacityLeft >= numOfPlayers && !disableByTime) {
        availableSlots.push(slot)
      }
    })

    return availableSlots
  }

  return happening.availabilities
}

export default getAvailabilities
